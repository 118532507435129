import React from "react";
import { Link } from "./Link";
import { Section, SectionTitle } from "./Section";
import {
  getMondayAppsDevelopmentUrl,
  getTeamExtensionUrl,
  getProductDevelopmentUrl,
} from "../utils/urls";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import webAppImg from "../images/undraw/web-app.svg";
import mondayAppsImg from "../images/undraw/monday-apps.svg";
import teamImg from "../images/undraw/team-extension.svg";
import * as styles from "./Services.module.scss";

type ServiceProps = {
  url: string;
  title: string;
  imgSrc: string;
};
const Service: React.FC<ServiceProps> = ({ title, url, imgSrc, children }) => {
  const [ref, inView] = useInView();

  return (
    <li ref={ref} className={cn("show-up", { visible: inView })}>
      <Link to={url}>
        <div className={styles.imgwrap}>
          <img alt={title} src={imgSrc} />
        </div>
        <h2>{title}</h2>
        {children}
      </Link>
    </li>
  );
};

export const ServicesList = () => {
  return (
    <ul className={styles.services}>
      <Service
        imgSrc={mondayAppsImg}
        url={getMondayAppsDevelopmentUrl()}
        title="monday.com Apps"
      >
        <p>Customize monday.com platform for your specific workflows.</p>
        <ul>
          <li>Integrate third-party systems</li>
          <li>Automate processes</li>
          <li>Improve workflows</li>
        </ul>
      </Service>

      <Service
        imgSrc={webAppImg}
        url={getProductDevelopmentUrl()}
        title="Product Development"
      >
        <p>
          Ensure your business stays ahead with tailored software solutions.
        </p>
        <ul>
          <li>Cover your unique business needs</li>
          <li>Gain competitive advantage</li>
          <li>Reduce long-term costs</li>
        </ul>
      </Service>

      <Service
        imgSrc={teamImg}
        url={getTeamExtensionUrl()}
        title="Team Extension"
      >
        <p>Hire a professional team of experts with the required skills.</p>
        <ul>
          <li>Complete control at every step</li>
          <li>Zero administration hassle</li>
          <li>Cost-effective hiring</li>
        </ul>
      </Service>
    </ul>
  );
};

export const ServicesSection = () => {
  return (
    <Section className={styles.services_section}>
      <SectionTitle className={styles.title}>Our Services</SectionTitle>
      <ServicesList />
    </Section>
  );
};
